import { useQuery, useMutation, useQueryClient } from 'react-query';
import { OrganizationsApiClient, Organization } from '../services/ApiService';

import { repofilesyncApiUrl } from 'config';
import axiosInstance from '../utils/axios';
import { queryKey as usersQueryKey } from '../api/Users';

const apiClient = new OrganizationsApiClient(repofilesyncApiUrl, axiosInstance);
export const queryKey = 'organization';

export const useGetOrganization = (id: number | undefined, enabled: boolean | undefined = true) => {
  return useQuery<Organization, Error>(
    [queryKey, id],
    async () => {
      if (!!id) {
        return apiClient.organizationsGet(id);
      }
      throw new Error('Id must not be undefined');
    },
    {
      enabled: enabled && !!id
    }
  );
};

export const useCreateOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation<Organization, Error, Organization>(
    (Organization: Organization) => apiClient.organizationsPost(Organization),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
        // re-load users organization memberships.
        queryClient.invalidateQueries(usersQueryKey);
      }
    }
  );
};

export const useUpdateOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, Organization>(
    (Organization: Organization) => {
      if (!!Organization?.organizationId) {
        return apiClient.organizationsPut(Organization.organizationId, Organization);
      } else {
        throw new Error('Request Id must not be null');
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
        // re-load users organization memberships.
        queryClient.invalidateQueries(usersQueryKey);
      }
    }
  );
};

export const useDeleteOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, number>((id: number) => apiClient.organizationsDelete(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      // re-load users organization memberships.
      queryClient.invalidateQueries(usersQueryKey);
    }
  });
};
