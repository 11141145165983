import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Container, Typography, CardContent } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from 'components/Page';
import { MHidden } from 'components/@material-extend';
import { LoginForm } from 'sections/authentication/login';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  backgroundPositionX: 'center',
  backgroundSize: 'cover',
  backgroundImage: `url(${'/static/login-register-background.jpg'})`,
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="Login | RepoFileSync">
      <AuthLayout>
        Don’t have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
          Sign Up
        </Link>
      </AuthLayout>

      <Container maxWidth="sm">
        <ContentStyle>
          <Card>
            <CardContent>
              <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h4" gutterBottom>
                    Log in to your account
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }}>
                    Don’t have an account? &nbsp;
                    <Link
                      underline="none"
                      variant="subtitle2"
                      component={RouterLink}
                      to={PATH_AUTH.register}
                    >
                      Sign Up
                    </Link>
                  </Typography>
                </Box>
              </Stack>

              {/* Disable OAuth socials for now */}
              {/* <AuthFirebaseSocials /> */}

              <LoginForm />
            </CardContent>
          </Card>
          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                Sign Up
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
