// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500'
};

export const PATH_UPLOAD = {
  root: path(ROOTS_DASHBOARD, '/upload'),
  view: (urlLinkCode: string) => path(ROOTS_DASHBOARD, `/upload/${urlLinkCode}`),
  success: (urlLinkCode: string) => path(ROOTS_DASHBOARD, `/upload/${urlLinkCode}/success`)
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  uploadRequirement: {
    root: path(ROOTS_DASHBOARD, '/upload-requirement'),
    list: path(ROOTS_DASHBOARD, '/upload-requirement/list'),
    newUploadRequirement: path(ROOTS_DASHBOARD, '/upload-requirement/new')
  },
  receive: {
    root: path(ROOTS_DASHBOARD, '/receive'),
    newUploadRequest: path(ROOTS_DASHBOARD, '/receive/new'),
    editUploadRequest: path(ROOTS_DASHBOARD, '/receive/')
  },
  uploadRequest: {
    root: path(ROOTS_DASHBOARD, '/upload-request'),
    list: path(ROOTS_DASHBOARD, '/upload-request/list'),
    listByRequirement: path(ROOTS_DASHBOARD, '/upload-request/list/requirement')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    account: {
      root: path(ROOTS_DASHBOARD, '/user/account'),
      general: path(ROOTS_DASHBOARD, '/user/account/general'),
      billing: path(ROOTS_DASHBOARD, '/user/account/billing')
    }
  },
  organization: {
    root: path(ROOTS_DASHBOARD, '/organization')
  },
  subscriptions: {
    root: path(ROOTS_DASHBOARD, '/subscriptions'),
    success: path(ROOTS_DASHBOARD, '/subscriptions/success')
  },
  management: {
    requestAFeature: path(ROOTS_DASHBOARD, '/suggest-improvement')
  }
};

export const PATH_DOCS = 'https://repofilesync.com/';
export const PATH_TOS = 'https://repofilesync.com/terms-of-service';
export const PATH_PRIVACY = 'https://repofilesync.com/privacy';
